import  quran  from '../../assets/qur.jpg';
import  islamic  from '../../assets/islamic.jpg';
import  arabic  from '../../assets/arabic-language.jpg';
import { useTranslation } from 'react-i18next';
import { ItemOfCources } from '../../othercomponents';
import './Courses.css';

function Courses() {
    const [t] = useTranslation();
    return (
        <div className="courses padding" id='our-courses'>
            <div className="courses-header heading-sections">
                <h2 > {t('our courses')} </h2>
            </div>
            <div className="courses-content">
                <ItemOfCources link ={t('quran courses')} src = {quran} path = '/quran-courses' />
                <ItemOfCources link ={t('islamic studies')} src = {islamic} path = '/islamic' />
                <ItemOfCources link ={t('arabic language')} src = {arabic} path = '/arabic'  />
            </div>
        </div>
    )
}

export default Courses;