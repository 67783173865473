import { Contacts, MainLink } from '../../othercomponents/index';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './Footer.css';


function Footer() {

    const [t] = useTranslation();

    return(
        <div className="footer">
            <div className="footer-content">
                <div className="footer-links">
                    <h3 className='footer-menu-head footer-head'> {t('menu')} </h3>
                    <ul className='footer-links-ul'>
                        <li className='footer-links-ul-li'>
                            <MainLink title={t('home')} to='/' />
                        </li>
                        <li className='footer-links-ul-li'>
                            <MainLink title={t('quran courses')} to='/quran-courses' />
                        </li>
                        <li className='footer-links-ul-li'>
                            <MainLink title={t('islamic studies')} to='/islamic' />
                        </li>
                        <li className='footer-links-ul-li'>
                            <MainLink title={t('arabic language')} to='/arabic' />
                        </li>
                        <li className='footer-links-ul-li'>
                            <MainLink title={t('about academy')} to='/about' />
                        </li>
                        <li className='footer-links-ul-li'>
                            <MainLink title={t('contact us')} to='/contact' />
                        </li>
                    </ul>
                </div>
                <div className="footer-contacts">
                    <h3 className='footer-contacts-head footer-head'>{t('contact us')}</h3>
                    <div className="footer-container">
                        <Contacts />
                    </div>
                </div>
            </div>
            <div className="footer-copy-right">
                <p className='copy-right-text'>
                    <Link to='/'>{t('Nour Al Quran')}</Link> {t('All Rights Reserved.')} 
                </p>
            </div>
        </div>
    )
}

export default Footer;