import React, { useState, useRef } from 'react';
import emailjs from '@emailjs/browser';
import { Input } from '../../othercomponents/index';
import { Message } from '../../othercomponents/index';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './Contact.css';

function Contact() {
    const [t] = useTranslation();
    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_zzd4l75', 
            'template_9kblxj3', 
            form.current, 
            'YpQSxGtzz2bm73j3B')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    };

    const [phoneNumber, setPhoneNumber] = useState('');
    const [valid, setValid] = useState(true);

    const handelChange = (value) => {

        setPhoneNumber(value);
        setValid(validatePhoneNumber(value));
    } 

    const validatePhoneNumber = (phoneNumber) => {
        const phoneNumberPattern = /^d(10)$/;
        return phoneNumberPattern.test(phoneNumber);
    }


    return(
        <div className="contact">
            <div className="heading-sections">
                <h2 className='contact-head'> {t('email us to get your free trial experience')} </h2>
            </div>
            <form ref={form} onSubmit={sendEmail} className='contact-form' action="form">
                <Input class='form-field-input' title={t('your name')} type = 'text' name='name' />
                <Input class='form-field-input' title={t('your email')} type = 'email' name='email' />
                <div className="form-field form-number">
                    <label className='form-field-label'>{t('your phone')}</label>
                    <PhoneInput 
                        country={'eg'}
                        name='phone' required 
                        type='number' 
                        value={phoneNumber} 
                        onChange={handelChange}
                        inputProps={{
                            required : true
                        }}
                    />
                </div>
                <Message name ='message'/>
                <Input class='form-field-submit' type = 'submit' value={t('SEND')} />
            </form>
        </div>
    )
}

export default Contact;