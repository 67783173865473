import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import './Special.css';

function Special() {
    const [t] = useTranslation();
    return(
        <div className="quran-type-contacts">
            <h5> {t('contact us')} </h5>
            <p>
                <span>  {t('Email')} : </span>
                <Link to="mailto:info@nourulquranacademy.com" >info@nourulquranacademy.com</Link>
            </p>
            <p>
                <span> {t('Whatsapp')} : </span>
                <Link to="https://wa.me/+966581256065" target='__blank'>+966581256065</Link>
            </p>
        </div>
    )
}

export default Special;