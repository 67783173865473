
import { useTranslation } from 'react-i18next';
import { DefaultPlayer as Video } from 'react-html5video';
import 'react-html5video/dist/styles.css';
import introVideo from '../../assets/intro.mp4';
import posterImage from '../../assets/poster.jpg';
import './Intro.css';

function Intro() {
    const [t] = useTranslation();
    return(
        <div className="intro">
            <div className="heading-sections">
                <h2>{t('intro video')}</h2>
            </div>
            <div className="intro-video">
            <Video autoPlay muted 
                poster={posterImage}
                >
                <source src={introVideo} type="video/webm" />
                <track label="English" kind="subtitles" srcLang="en" src="http://source.vtt" default />
        </Video>
            </div>
        </div>
    )
}

export default Intro;