import { useRef } from 'react';
import { Input } from '../../othercomponents';
import emailjs from '@emailjs/browser';
import { useTranslation } from 'react-i18next'; 
import formImage from '../../assets/form.jpg';
import './SpecialContact.css';

export default function SpecialContact() {

    const [t] = useTranslation();

    const form = useRef();
    const sendEmail = (e) => {
        e.preventDefault();

        emailjs.sendForm(
            'service_zzd4l75', 
            'template_9kblxj3', 
            form.current, 
            'YpQSxGtzz2bm73j3B')
        .then((result) => {
            console.log(result.text);
        }, (error) => {
            console.log(error.text);
        });
        e.target.reset();
    };

  return (
    <div className="special">
        <div className='special-contact padding'>
            <div className="special-contact-image">
                <img src={formImage} alt="" />
            </div>
            <form ref={form} onSubmit={sendEmail} className='special-contact-form' action="form">
                <h4> {t('book with us now')} </h4>
                <Input class='form-field-input' title={t('your name')} type = 'text' name='name' />
                <Input class='form-field-input' title={t('your email')} type = 'email' name='email' />
                <Input class='form-field-input' title={t('your phone')} type = 'number' name='phone' />
                <Input class=' form-field-submit' type = 'submit' value={t('Book Now')} />
            </form>
        </div>
    </div>
  )
}
