import './Home.css';
import { Hero, Courses, Intro, Why, SpecialContact } from '../../components/index';

function Home() {
    return(
        <>
            <Hero />
            <Intro />
            <Why />
            <Courses />
            <SpecialContact />
        </>
    )
}

export default Home;