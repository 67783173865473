import LogoImg from '../../assets/Logo.png';
import { Link } from 'react-router-dom';
import './Logo.css';

function Logo() {
    return(
        <div className="navbar-logo">
            <Link className='navbar-logo-link' to='/'>
                <img className='navbar-logo-link-img' src={LogoImg} alt="" />
            </Link>
        </div>
        
    );
};


export default Logo;