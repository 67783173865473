import React, { useEffect, useRef } from 'react';
import Typed from 'typed.js';
import { useTranslation } from 'react-i18next';
import './Hero.css';

function Hero() {

    const [t] = useTranslation()
    const typedRef = useRef(null);

    useEffect(() => {
        const options = {
        strings: [t('We are proud to help people learn the Quran most efficiently and quickly.')],
        typeSpeed: 50,
        backSpeed: 30,
        loop: false
        };

        const typed = new Typed(typedRef.current, options);

        return () => {
        // Cleanup on component unmount
        typed.destroy();
        };
    }, [t]);
    
   
    return(
        <div className="hero padding">
            <div className='hero-content'>
                <div className="hero-content-text">
                    <span className="hero-content-text-span" ref={typedRef}>
                       
                    </span>
                </div>
                <div className="hero-content-buttons">
                    <a href="/contact" > 
                        <button class="button-82-pushable" role="button">
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text">
                                {t('contact us')} 
                            </span>
                        </button>
                    </a>

                    <a href="https://wa.me/+201018278920" target='__blank' > 
                        <button class="button-82-pushable special-hero-btn" role="button">
                            <span class="button-82-shadow"></span>
                            <span class="button-82-edge"></span>
                            <span class="button-82-front text special-hero-case">
                                {t('get your free session')}
                            </span>
                        </button>
                     </a>
                </div>
            </div>
        </div>
    )
}

export default Hero;
