import { WhyItem } from '../../othercomponents';
import whyImage from '../../assets/why.webp';
import { useTranslation } from 'react-i18next';
import './Why.css';


export default function Why() {

    const [t] = useTranslation();

  return (
    <div className="why padding" >
        <div className="why-header heading-sections">
            <h2> {t('why stydy with us ?')} </h2>
        </div>
        <div className="why-content">
            <div className="why-content-image">
                <img src={whyImage} alt="" />
            </div>
            <div className="why-content-details">
                <WhyItem text = {t('Because we are a comprehensive academy for teaching Arabic speakers and non-native speakers the Holy Quran and the Arabic language for all ages and levels.')} />
                <WhyItem text = {t('We have male and female teachers for all academic subjects, all curricula (Qatari - Kuwaiti - Saudi - Emirati).')} />
                <WhyItem text = {t('We have certified, qualified, and highly experienced Azhari teachers for those who want to take an Ijazah.')} />
                <WhyItem text = {t('We provide quarterly reports on the progress and development of our students.')} />
                <WhyItem text = {t('We use interactive and attractive educational methods to teach children.')} />
            </div>
        </div>
    </div>
  )
}
