import { RiQuillPenFill } from "react-icons/ri";
import './WhyItem.css'

export default function WhyItem(props) {
  return (
    <div className='why-content-item'>
        <div className="why-content-item-icon">
            <RiQuillPenFill size={25} color="#fff" />
        </div>
        <div className="why-content-item-text">
            <p className='why-content-item-text-p'> {props.text} </p>
        </div>
    </div>
  )
}
