import './ItemOfCources.css';

export default function ItemOfCources(props) {
  return (
    <div className="courses-type">
        <div className="courses-type-image">
            <img src={props.src} alt="" />
        </div>
        <div className="course-link">
            <a href={props.path}>{props.link}</a>
        </div>
    </div>
  )
}
