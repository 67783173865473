import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import { Navbar, Footer } from './components';
import { Home, QuranLearning, ContactPage, Ijazah, About, Recitation, Memorization, Islamic, Arabic } from './Pages/index';
import { ContactScroll } from './othercomponents';
import Logo from './assets/Logo.png';
import './App.css';


function App() {

    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
      // تحميل المحتوى
      fetchData();
    }, []);
  
    const fetchData = () => {
      // قم بتحميل المحتوى هنا (على سبيل المثال، استخدم fetch أو axios)
      // في هذا المثال، سنستخدم setTimeOut لتمثيل فترة زمنية قبل تحميل المحتوى
      setTimeout(() => {
        // بمجرد الانتهاء من التحميل، غير حالة التحميل
        setIsLoading(false);
      }, 1500); // افتراضيا بعد 3 ثواني
    };

    return(
        <>
            {isLoading
             ?
             <div className="loading-container">
                <div className="spinner"></div>
            </div>
          : 
            <div className='app'>
                <Router basename={'/'}>
                    <Navbar />
                    <ContactScroll />  
                        <Routes>
                            <Route path='' element={<Home />} />
                            <Route path='/about' element={<About />}/>
                            <Route path='/contact' element={<ContactPage />} />
                            <Route path='/quran-courses' element={<QuranLearning />} />
                            <Route path='/quran-courses/recitation' element={<Recitation />} />
                            <Route path='/quran-courses/memorization' element={<Memorization />} />
                            <Route path='/quran-courses/ijazah' element={<Ijazah />} />
                            <Route path='/islamic' element={<Islamic />} />
                            <Route path='/arabic' element={<Arabic />} />
                        </Routes>
                    <Footer />
                </Router>
            </div>
            }
        </>
    )
}

export default App;